.btn-a {
	@include btn ($a, $l, $a, $l, transparent, 50px, 22px, 10px 20px, 1);
	box-shadow: 2px 2px 7px $a; }
.btn-t {
	@include btn ($a, $l, transparent, $l, $a, 50px, 22px, 10px 20px, 1); }
input, textarea, select {
	@include btn ($a, $l, transparent, $l, transparent, 0, 22px, 10px 0, 1);
	border: {
		top: 0;
		left: 0;
		right: 0; }
	text-align: left;
	font-family: montserrat; }
.link {
	@include link ($l, 1px, 0);
	font-size: 22px;
	&:hover {
		color: $a; } }
.select {
	position: relative;
	&::after {
		content: '';
		position: absolute;
		width: 10px;
		height: 10px;
		border: {
			top: 1px solid $l;
			left: 1px solid $l; }
		top: 13px;
		right: 20px;
		z-index: 10;
		transform: rotate(45deg + 180deg);
		cursor: pointer;
		pointer-events: none; }
	select {
		width: 100%; }
	option {
		background-color: $d; } }
