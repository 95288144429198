* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }
*::-webkit-input-placeholder {
	color: lighten(#000, 40%);
	opacity: 1; }
*:-moz-placeholder {
	color: lighten(#000, 40%);
	opacity: 1; }
*::-moz-placeholder {
	color: lighten(#000, 40%);
	opacity: 1; }
*:-ms-input-placeholder {
	color: lighten(#000, 40%);
	opacity: 1; }
*::selection {
  color: $l;
  background: $a; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: $l; }
body input:required:valid,
body textarea:required:valid {
	color: $l; }
.hidden {
	display: none; }
html, body {
	height: 100%;
	width: 100%;
	margin: 0;
	overflow: hidden;
	-webkit-overflow-scrolling: touch; }
*::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px lighten($a, 80%); }
*::-webkit-scrollbar {
	width: 4px; }
*::-webkit-scrollbar-thumb {
	background-color: $a; }
body {
	font-size: 18px;
	min-width: 320px;
	width: 100%;
	position: relative;
	line-height: 1.5;
	font-family: $default-font;
	opacity: 1;
	color: $l;
	background-color: $d;
	padding: 0!important; }
h1,h2,h3,h4,h5,h6,ul,menu,li {
	margin: 0;
	padding: 0; }
input, textarea, a, button, select {
	outline: none;
	-webkit-overflow-scrolling: touch; }
a {
	text-decoration: none;
	-webkit-overflow-scrolling: touch; }
ul {
	padding: 0;
	margin: 0;
	-webkit-overflow-scrolling: touch; }
li {
	list-style-type: none;
	padding: 0;
	margin: 0;
	-webkit-overflow-scrolling: touch; }
p {
	margin: 0 0 20px 0;
	padding: 0;
	-webkit-overflow-scrolling: touch; }
select {
	appearance: none;
	-webkit-overflow-scrolling: touch; }
address {
	font-style: normal;
	-webkit-overflow-scrolling: touch; }
