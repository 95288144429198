.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1001;
	width: 100%;
	padding: 20px 0;
	transition: 0.5s;
	.cont-fluid {
		justify-content: space-between;
		align-items: center; }
	.active-link {
		color: $a;
		&::after, &::before {
			width: 50%; } } }
.top-mnu {
	display: flex;
	justify-content: space-between;
	width: 50%;
	.submenu {
		position: absolute;
		background-color: $d;
		z-index: 2000;
		transition: opacity 0.5s, transform 0.5s 0.5s;
		transform-origin: 50% 0;
		transform: rotateX(90deg);
		opacity: 0;
		padding: 0 20px 20px 10px;
		li {
			margin-top: 10px;
			position: relative; }
		.submenu_l_2 {
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			z-index: -100;
			transition: 0.5s;
			background-color: $d;
			padding: 10px; } }
	li {
		&:hover {
			.submenu {
				opacity: 1;
				transform: rotate(0);
				transition: opacity 0.5s, transform 0s;
				background-color: $d; } } }
	li {
		li {
			li {
				&:hover {
					.submenu_l_2 {
						opacity: 1;
						z-index: 200;
						left: calc(100% + 20px); } } } } } }
.header-info {
	display: flex;
	justify-content: flex-end;
	width: 40%;
	align-items: center;
	.phone {
		display: flex;
		margin-right: 20px;
		align-items: center;
		.link {
			&::after, &::before {
				bottom: -7px; } }
		img {
			width: 35px;
			height: 35px; } }
	.mail {
		display: flex; }
	.link {
		margin-left: 15px;
		line-height: 1; } }
.mob-mnu {
	position: fixed;
	width: 80%;
	padding: 15px 0 15px 15px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	z-index: 0;
	background-color: $d;
	border-right: 1px solid $a;
	.screen-link {
		padding: 10px 0;
		font-size: 20px;
		transition: 0.5s; }
	a {
		color: $l;
		transition: 0.5s; }
	.active-link {
		color: $a;
		&::after, &::before {
			width: 50%; } } }
.logo {
	font-size: 30px; }
.bottom-bar {
	height: 50px;
	display: flex;
	justify-content: flex-start;
	img {
		width: 30px;
		margin-right: 20px; } }

.screen-dots {
	position: fixed;
	z-index: 400;
	top: 0;
	bottom: 0;
	right: 10px;
	height: 100%;
	width: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	display: none;
	.screen-link {
		margin: 25px 0; }
	a {
		color: $l;
		font-weight: light;
		transition: .3s;
		&:hover {
			color: $a; } } }

.blocker, .blocker-popup-inner {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.57);
	z-index: -100;
	opacity: 0;
	transition: 0.5s;
	cursor: pointer; }

.to-top {
	position: fixed;
	bottom: 10px;
	right: 10px;
	z-index: 9999;
	opacity: 1;
	transition: 0.5s; }

.progress {
	position: fixed;
	top: 0;
	left: 0;
	width: 0;
	background-color: $a;
	height: 4px;
	z-index: 1003; }

.hamburger {
	cursor: pointer;
	position: fixed;
	top: 20px;
	left: 20px;
	z-index: 1002;
	transition: 0.5s;
	width: 20px;
	height: 10px;
	transition: 0.5s;
	display: none;
	&::after,&::before {
		content: '';
		position: absolute;
		height: 1px;
		width: 20px;
		background-color: $a;
		transition: 0.5s; }
	&::before {
		top: 0; }
	&::after {
		top: 10px; } }
.inner-hamb {
	position: absolute;
	width: 20px;
	height: 1px;
	top: 5px;
	background-color: $a;
	transition: 0.5s; }

#politics {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: $l;
	transition: 0.5s;
	color: $d;
	opacity: 0;
	overflow-y: scroll;
	padding: 30px;
	ul {
		padding-left: 10px; }
	li {
		padding-left: 10px; } }
#politics::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px lighten($d, 80%); }
#politics::-webkit-scrollbar {
	width: 4px; }
#politics::-webkit-scrollbar-thumb {
	background-color: $d; }
.close-politics {
	@include close-icon (25px, $d, 3px);
	top: 10px;
	right: 10px;
	cursor: pointer;
	position: absolute;
	&:hover {
		transform: scale(1.2); } }

#callback, #consult, #order {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	transition: 0.5s;
	padding: 10px;
	opacity: 0;
	display: flex; }
.callback-inner, .consult-inner {
	position: relative;
	margin: auto;
	padding: 30px;
	max-width: 600px;
	min-width: 300px;
	background-color: $d;
	border: solid $a 1px; }
.close-callback, .close-consult {
	@include close-icon (25px, $l, 3px);
	top: 10px;
	right: 10px;
	cursor: pointer;
	position: absolute;
	&:hover {
		transform: scale(1.2); } }

.ajax-results {
	.success, .error {
		height: 0;
		opacity: 0;
		max-height: max-content;
		margin-top: 10px;
		overflow: hidden;
		transition: 0.3s; }
	.active {
		margin-top: 20px;
		height: 50px;
		opacity: 1; }
	.success {
		color: green; }
	.error {
		color: red; } }

.about-service {
	display: flex;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: $d;
	opacity: 0;
	transition: 0.5s;
	.h4 {
		text-align: center;
		padding: 0 40px; } }
table {
	margin: auto; }
td, th {
	border: $bord-a;
	padding: 8px 20px;
	text-align: center; }
.close-service {
	@include close-icon (25px, $l, 3px);
	top: 10px;
	right: 10px;
	cursor: pointer;
	position: absolute; }
