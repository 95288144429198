.h1 {
	font-size: 90px;
	font-weight: 600;
	line-height: 1; }
.h2 {
	font-size: 40px;
	font-weight: 600;
	line-height: 1;
	margin: 40px 0 40px 0; }
.h3 {
	font-size: 68px;
	font-weight: 400;
	line-height: 1; }
.h4 {
	font-size: 30px;
	font-weight: 600;
	line-height: 1.3;
	margin: 20px 0; }
