.active {
	z-index: 100;
	opacity: 1;
	transform: translate(0); }
.active-prev {
	transform: translateY(-100%); }
.active-next {
	transform: translateY(100%); }

.top-mnu {
	.active-link {
		.link {
			color: $a;
			&::before, &::after {
				width: 50%; } } } }
.mob-mnu {
	.active-link {
		a {
			color: $a; } } }

.mob-mnu-active {
	.page {
		transform: translateX(80%); }
	.blocker {
		z-index: 9998;
		opacity: 1; } }

.hamburger-active {
	&::after {
		transform: rotate(45deg);
		top: 5px; }
	&::before {
		transform: rotate(-45deg);
		top: 5px; }
	.inner-hamb {
		opacity: 0;
		transform: translateX(100px); } }

.popup-open-politics {
	#politics {
		opacity: 1; } }

.popup-order-service {
	.blocker {
		z-index: 9998;
		opacity: 1; }
	#callback, #consult {
		opacity: 1; } }
.popup-open-callback, .popup-open-consult {
	.blocker {
		z-index: 9998;
		opacity: 1; }
	#callback, #consult {
		opacity: 1; } }

.popup-about-service-popup {
	.about-service {
		opacity: 1; } }

.active-slide {
	.item {
		opacity: 1; } }

.screen-dots {
	.active-link {
		a {
			color: $a; } } }

.about-service {
	.cont-fluid {
		z-index: 99; } }
.popup-order-popup-1, .popup-order-popup-2, .popup-order-popup-3 {
	.blocker-popup-inner {
		opacity: 1;
		z-index: 999; }
	#callback {
		opacity: 1; } }
