@import "fonts";
@import "vars";
@import "base";
@import "grid";
@import "preloader";
@import "_mixins/btns";
@import "_mixins/links";
@import "_mixins/icons";
@import "resets";
@import "btn_forms_elem";
@import "titles";
@import "fixed";

.cont {
	display: flex;
	width: 100%;
	justify-content: space-around; }

.price {
	font-weight: 300; }
.btnbar {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	a {
		width: 180px;
		margin-top: 20px; } }

//Slider default style
.item {
	width: 100%;
	padding: 0 40px 30px 0;
	opacity: 0;
	transition: 1s; }
.inner {
	width: 100%;
	transition: 0.5s;
	img {
		width: 100px;
		height: 100px;
		border-radius: 100px; } }

.control {
	cursor: pointer;
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto;
	z-index: 300;
	// transition: 0.5s
	&:hover {
		transform: scale(1.2); } }
.prev {
	left: -15px;
	transition: 0.5s; }
.next {
	right: -15px;
	transition: 0.5s; }

.slider-dots {
	position: absolute;
	bottom: -30px;
	width: 100%;
	left: 0;
	display: none;
	justify-content: center;
	align-items: center;
	transition: 0.5s; }
.dot {
	width: 6px;
	height: 6px;
	background-color: $l;
	margin: 0 10px;
	border-radius: 20px;
	cursor: pointer;
	transition: 0.2s;
	&:hover {
		width: 10px;
		height: 10px; } }
.dot-active {
	width: 10px;
	height: 10px;
	justify-content: space-around;
	align-items: center; }

.filter {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 80%;
	padding-top: 30px; }
.filter-link {
	color: $l;
	font-size: 24px;
	transition: 0.5s;
	position: relative;
	&::before,&::after {
		content: '';
		position: absolute;
		top: 4px;
		bottom: 0;
		left: 0;
		margin: auto;
		width: 100%;
		height: 3px;
		background-color: $l;
		transition: 0.5s; }
	&:hover {
		&::before,&::after {
			left: -30px;
			width: 20px;
			height: 1px; } } }
.filter-active {
	&::before,&::after {
		left: -30px;
		width: 20px;
		height: 1px; } }

.slider-rebuild {
	.slider-preload {
		opacity: 1; }
	.preload-inner {
		width: 100%; }
	.slide-line {
		opacity: 0; } }
.slider-preload {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 60%;
	height: 100%;
	background-color: $d;
	opacity: 0;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center; }
.preload-inner {
	width: 0;
	height: 1px;
	background-color: $l;
	transition: 0.9s; }

.info-block {
	margin: 50px 85px 0 0;
	p {
		margin: 20px 0 20px 0; } }

.title {
	justify-content: flex-start;
	padding-bottom: 60px; }
.fluid-btn {
	width: 100%;
	text-align: center;
	padding: 30px 0 0 0;
	display: flex;
	justify-content: center; }

.we-are {
	height: 100vh;
	.cont-fluid {
		justify-content: flex-start; }
	.btn-a {
		font-size: 30px;
		padding: 22px 41px; } }

.about {
	.item-fluid {
		padding: 0 0 20px 0;
		&:nth-child(2), &:nth-child(5) {
			padding: 0 20px 20px 20px; } } }

.services {
  .item-fluid {
    padding: 0;
    &:nth-child(2) {
      padding: 0 20px; } } }
#services {
	.cont-fluid {
		flex-wrap: wrap;
		align-items: stretch;
  .item-fluid {
    padding: 0;
    &:nth-child(2) {
      padding: 0 20px; } }
		.item-inner {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			height: 100%;
			justify-content: flex-start;
			.h4 {
				min-height: 39px; } }
		.btnbar {
			margin-top: auto; } }
	.services-text {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 1; } }

.faq {
	height: auto;
	.h4 {
		margin-top: 40px; }
	.cont-fluid {
		flex-direction: column;
		justify-content: flex-start; } }
#faq {
	input, textarea {
		resize: none;
		overflow: hidden;
		width: 100%; }
	textarea {
		margin-top: 20px;
		white-space: pre-wrap; }
	.question {
		margin-left: 30px;
		.form-inputs {
			margin: 30px 0 20px 0;
			input {
				margin-right: 40px; } } }
	.title {
		padding-bottom: 0; } }

.contacts {
	.item-inner {
		display: flex;
		align-items: center;
		a {
			font-size: 26px;
			margin-left: 35px;
			white-space: nowrap; } }
	.cont-fluid {
		justify-content: flex-start;
		align-items: center; }
	.item-fluid {
		padding: 40px 0 0 0;
		img {
			width: 60px; } }
	p {
		font-size: 22px;
		margin: 0; }
	.credits {
		margin-top: 40px;
		.link {
			text-transform: uppercase;
			color: $a; } } }
.form-inputs {
	margin: 30px 0 20px 0;
	input {
		margin-right: 40px; } }

.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	left: 0;
	margin: auto;
	z-index: 100;
	background-size: cover;
	background-repeat: no-repeat;
	transition: 0.1s linear;
	display: none; }

.call-soc {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	img {
		width: 100%; } }

//Icons example
.triangle-top {
	@include triangle-top (20px, $a); }
.triangle-bottom {
	@include triangle-bottom (20px, $a); }
.triangle-left {
	@include triangle-left (20px, $a); }
.triangle-right {
	@include triangle-right (20px, $a); }
.triangle-top-right {
	@include triangle-top-right (15px, $l); }
.triangle-top-left {
	@include triangle-top-left (15px, $l); }
.triangle-bottom-right {
	@include triangle-bottom-right (15px, $l); }
.triangle-bottom-left {
	@include triangle-bottom-left (15px, $l); }

.angle-top {
	@include angle-top (30px, $l, 2px); }
.angle-bottom {
	@include angle-bottom (30px, $l, 2px); }
.angle-left {
	@include angle-left (30px, $l, 2px); }
.angle-right {
	@include angle-right (30px, $l, 2px); }

.arrow-back {
	@include arrow-back (40px, $l, 2px); }
.arrow-next {
	@include arrow-next (40px, $l, 2px); }
.arrow-up {
	@include arrow-up (40px, $l, 2px); }
.arrow-down {
	@include arrow-down (40px, $l, 2px); }

.button {
	text-align: center; }
.btn-a {
	display: inline-block;
	margin-top: 20px; }

.accent-theme {
	background-color: $d; }

.service-desc {
	.cont-fluid {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 30px 0;
		.desc {
			width: 50%;
			h1 {
				text-align: center; } } }
	.close {
		position: absolute;
		top: -30px;
		right: 20px;
		width: 60px;
		height: 60px;
		&::after, &::before {
			width: 30px; } } }
.body-service {
	.link[href="#services"] {
		color: $a;
		&::after, &::before {
			width: 50%; } } }

@import "anim";
@import "media";
