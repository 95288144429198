.page {
	overflow: hidden;
	transition: 0.7s;
	height: 100%;
	width: 100%;
	background-color: $d;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100; }
.sect {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 0;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-color: transparent;
	width: 100%;
	overflow: hidden;
	padding: 110px 0 100px 0; }
.screen-scroll {
	width: 100%;
	height: 100%;
	position: relative;
	overflow-y: scroll;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch; }
main {
	background-position-y: 0;
	background-position-x: center;
	background-size: cover;
	background-repeat: no-repeat; }
.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	height: 100%; }
.scrollable {
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	max-height: 100%;
	padding: 0 20px 0 0;
	transition: 0.5s; }
.scrollable::-webkit-scrollbar-track {
	box-shadow: inset 0 0 6px lighten($a, 80%); }
.scrollable::-webkit-scrollbar {
	width: 4px; }
.scrollable::-webkit-scrollbar-thumb {
	background-color: $a; }
.screen-link {
	cursor: pointer; }
.polit {
	font-size: 10px;
	line-height: 1.2;
	margin-top: 10px;
	.open-politics {
		color: $l;
		border-bottom: $bord-l; } }
////SLIDER////
.slider {
	width: 100%;
	position: relative;
	cursor: pointer;
	padding: 0 60px; }
.viewport {
	width: 100%;
	overflow: hidden;
	position: relative;
	transition: 0.5s; }
.slide-line {
	transition: 0.5s;
	overflow-y: hidden; }
.slide {
	float: left; }
