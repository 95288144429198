@import "_mixins/font-face";

// +font-face("roboto-example", "../fonts/RobotoRegular/RobotoRegular")
// +font-face("roboto-example", "../fonts/RobotoBold/RobotoBold", bold)

// +font-face("ProximaNova", "../fonts/ProximaNova/ProximaNova-Regular")
// +font-face("ProximaNova", "../fonts/ProximaNova/ProximaNova-Bold", bold)

@include font-face("montserrat", "../fonts/Montserrat/Montserrat-Light", 300);
@include font-face("montserrat", "../fonts/Montserrat/Montserrat-Regular");
@include font-face("montserrat", "../fonts/Montserrat/Montserrat-Bold", bold);
@include font-face("montserrat", "../fonts/Montserrat/Montserrat-SemiBold", 600);
// +font-face("raleway", "../fonts/Raleway/Raleway-Black", 900)
// +font-face("raleway", "../fonts/Raleway/Raleway-ExtraBold", 800)
// +font-face("raleway", "../fonts/Raleway/Raleway-Medium", 500)

// +font-face("open-sans", "../fonts/Open Sans/OpenSans-Regular")
// +font-face("open-sans", "../fonts/Open Sans/OpenSans-Bold", bold)
// +font-face("open-sans", "../fonts/Open Sans/OpenSans-Light", light)
// +font-face("open-sans", "../fonts/Open Sans/OpenSans-SemiBold", 600)
