.preloader {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: $d; }
.wrap-product-preloader {
  transform: scale(0.6);
  width: 80px;
  height: 80px;
  background-color: $a;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: wrap-preload 1s ease-in-out infinite; }
.inner-product-preloader {
  width: 50px;
  height: 50px;
  background-color: $a;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  &::after {
    content: '';
    width: 40px;
    height: 40px;
    background-color: $l;
    border-radius: 50%; }
  @keyframes wrap-preload {
    0% {
      transform: scale(0.6);
      opacity: 1; }
    100% {
      transform: none;
      opacity: 0; } } }
