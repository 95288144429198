@import "vars";

@media only screen and (max-width : 1600px) {
	body {
		font-size: 18px; }
	.h1 {
		font-size: 80px; }
	.h2 {
		font-size: 36px; }
	.h3 {
		font-size: 50px; }
	.h4 {
		font-size: 28px; }
	.header-info {
		width: 50%; }
	// .top-mnu
	// 	width: 45%
	.we-are {
		.btn-a {
			font-size: 25px; } }
	.service-desc {
		.cont-fluid {
			.desc {
				width: 70%; } } } }

@media only screen and (max-width : 1200px) {
	body {
		font-size: 18px; }
	.h4 {
		margin: 15px 0; }
	.title {
		padding-bottom: 40px; }
	.top-mnu {
		width: 52%; }
	.header-info {
		width: 42%;
		.mail {
			display: none; } }
	.faq .h4 {
		margin-top: 20px;
		margin-bottom: 10px; }
	.contacts {
		.item-inner {
			a {
				font-size: 24px;
				margin-left: 15px; }
			img {
				width: 60px;
				height: auto; } } }
	.service-desc {
		.cont-fluid {
			.desc {
				width: 100%; } } } }

@media only screen and (max-width : 992px) {
	body {
		font-size: 16px; }
	.header {
		top: 0;
		padding: 15px 0;
		.cont-fluid {
			width: 100%; } }
	.header-info {
		justify-content: flex-end;
		.phone {
			margin-right: 20px; } }
	.top-mnu {
		display: none; }
	.hamburger {
		display: flex; }
	.header-info {
		width: 100%; }
	.h1 {
		font-size: 66px; }
	.h2 {
		font-size: 32px; }
	.triangle-top {
		@include triangle-top (15px, $a); }
	.triangle-left {
		@include triangle-left (15px, $l); }
	.triangle-right {
		@include triangle-right (15px, $l); } }

@media only screen and (max-width : 768px) {
	body {
		font-size: 14px; }
	.h1 {
		font-size: 40px; }
	.h2 {
		font-size: 22px;
		margin: 20px 0 0 0; }
	.h3 {
		font-size: 30px; }
	.title {
		padding-bottom: 30px; }
	.h4 {
		font-size: 20px; }
	.sect {
		padding: 60px 0 40px 0; }
	.header-info {
		.link {
			font-size: 16px;
			margin-left: 8px; }
		.phone {
			align-items: center;
			img {
				width: 20px;
				height: 20px; } } }
	.we-are {
		.btn-a {
			font-size: 16px;
			padding: 10px 25px; } }
	.item-inner {
		img {
			width: 55px; } }
	.about, .services {
		.item-fluid {
			padding: 10px 30px 10px 0;
			&:nth-child(2), &:nth-child(5) {
				padding: 10px 30px 10px 0; } } }
	.faq {
		height: auto;
		.h4 {
			margin: 20px 0 10px 0; }
		.cont-fluid:nth-child(2) {
			padding: 0; }
		.scrollable {
			padding: 0 20px; }
		textarea {
			padding: {
				left: 0;
				right: 0; } } }
	#faq {
		.question {
			margin-left: 0; } }

	.item {
		padding: 10px 0;
		img {
			width: 80px;
			height: 80px; } }
	.contacts {
		.item-fluid {
			padding: 25px 0 0 0; }
		.item-inner {
			a {
				font-size: 16px;
				margin-left: 7px; }
			img {
				width: 35px; } }
		p, .link {
			font-size: 14px; } }
	.btn-a, .btn-t, input, textarea {
		font-size: 16px;
		padding: 8px 15px; }
	input {
		padding: {
			left: 0;
			right: 0; } }
	.triangle-top {
		@include triangle-top (10px, $a); }
	.triangle-left {
		@include triangle-left (10px, $a); }
	.triangle-right {
		@include triangle-right (10px, $a); }
	.fluid-btn {
		padding: 20px 0 0 0; }
	.slider {
		padding: 0 40px; } }

@media only screen and (max-width : 540px) {
	.contacts .item-fluid {
		width: 100%; }
	.title {
		padding-bottom: 20px; } }
@media only screen and (max-width : 480px) {
	body {
		font-size: 12px; }
	.h1 {
		font-size: 40px; }
	.h2 {
		font-size: 20px; }
	.h3 {
		font-size: 26px; }
	.h4 {
		margin: 10px 0;
		font-size: 18px; }
	.background-mobile__center {
		background-position: 75% 0; }
	.header-info {
		.btn-t {
			display: none; }
		.phone {
			margin: 0;
			img {
				width: 15px;
				height: 15px; } }
		.link {
			font-size: 12px; } }
	.we-are {
		.btn-a {
			font-size: 14px;
			padding: 12px 30px; } }
	.about, .services {
		.item-fluid {
			padding: 0 0 10px 0; } }
	p {
		margin-bottom: 10px; }
	.contacts {
		p {
			font-size: 12px; }
		.form-inputs {
			margin: 15px 0 30px 0; }
		.row {
			padding-top: 20px; }
		.item-fluid {
			padding: 10px 0 0 0; }
		.item-inner {
			a {
				font-size: 14px; }
			img {
				width: 28px; } } }
	.btn-a, .btn-t, input {
		font-size: 12px; }
	.scrollable {
		margin-top: 10px; }
	#services {
		.cont-fluid {
			.item-fluid {
				margin-bottom: 60px; }
			.item-fluid:nth-child(2) {
				padding: 0; } } }
	.faq {
		.h4 {
			margin: 40px 0 10px 0; } }
	form {
		.btn-a {
			margin-top: 0; } } }


@media only screen and (max-width : 320px) {
	body {
		font-size: 10px; } }
