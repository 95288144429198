.visible-xs {
	display: none; }
.cont-fluid {
	z-index: 999;
	display: flex;
	flex-direction: row;
	position: relative; }
.block {}
.col-xl-2 {
	width: 50%; }
.col-xl-3 {
	width: 33.333%; }
@import "vars";

.cont-fluid {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	position: relative;
	width: 1600px;
	margin: 0 auto;
	padding: 0 20px; }
.item-fluid {
	padding: 30px; }
.row {
	display: flex;
	flex-wrap: wrap; }
.col-xl-12 {
	width: 100%; }
.col-xl-8 {
	width: 66.666%; }
.col-xl-6 {
	width: 50%; }
.col-xl-3 {
	width: 33.333%; }

@media only screen and (max-width : 1600px) {
	.cont-fluid {
		width: 1200px; } }

@media only screen and (max-width : 1200px) {
	.cont-fluid {
		width: 992px; }
	.col-sm-12 {
		width: 100%; }
	.col-sm-8 {
		width: 66.666%; } }
@media only screen and (max-width : 992px) {
	.cont-fluid {
		width: 768px; } }

@media only screen and (max-width : 768px) {
	.col-xs-12 {
		width: 100%; }
	.col-xs-6 {
		width: 50%; }
	.hidden-xs {
		display: none; }
	.visible-xs {
		display: block; }
	.visible-flex-xs {
		display: flex; }
	.cont-fluid {
		width: 100%; } }

@media only screen and (max-width : 480px) {
	.col-xxs-12 {
		width: 100%; } }

@media only screen and (max-width : 320px) {
 }	//
